.TypingEffect{
    text-align: center;
    background-color:var(--intro);
    font-size: 50px;
    color:var(--text-primary);
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode:forwards;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}


@media screen and (max-width: 650px){
    .TypingEffect{
        font-size:8vw;
        animation: none;
        -webkit-animation: none;
}
}

@media screen and (max-width: 450px){
    .TypingEffect{
        font-size:7vw;
        animation:none;
        -webkit-animation:none 
}
}