.introduction{
    display:flex;
    background-color: var(--intro);
    align-items: center;
    justify-content:space-evenly;
    scroll-snap-type: y mandatory;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode:forwards;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
.Profile {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    padding:2%;
    width:50%;
}
.TypingEffect{
    font-size:30px;
}

.AboutMe {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 5%;
    padding-right:5%;

}
.AboutMe span {
    text-align: center;
}
.AboutMe a {
    padding:2%;
    text-decoration: none;
}
.AboutMe h1 {
    font-size: 2em; 
    text-align: center;
    margin-bottom:0px;
}
.SocialMedia{
    align-self: center;
    opacity: 85%;
}
.SocialMedia:hover{
    opacity: 100%;
}

@media screen and (max-width: 650px) {
    .AboutMe{
        border:none;
    }
    .introduction{
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
        animation: none;
        -webkit-animation: none;
}
    .BriefWelcome{
        border:none;
        font-size: 6vw;
    }
    .BriefWelcome h1{
        margin:0;
    }
    .Profile {
        width:50%;
    }
    .AboutMe h1{
        font-size: 5vw;
    }
    .AboutMe p {
        font-size:4vw;
    }

}





 