.timeline-title{
    text-align: center;
}


.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top:2%;
    margin-bottom:2%;
    scroll-snap-type: y mandatory;
}


/* this is the line */ 
.timeline-container::after {
    background-color: var(--timeline);
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}
/*50% from left - half of it's own width*/

.timeline-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}
/* it's content div will be to the end of the row which makes sense */
.timeline-item button{
    display:inline;
    border:none;
    opacity: 80%;
    background-color: var(--skillbutton);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}
.timeline-item button:hover{
    opacity:100%;
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
}
/* it's div will be to the start which makes sense */


.timeline-item-content {
    box-shadow: 0 0 5px var(--text-primary);
    /* this is to give the box look */
    border-radius: 5px;
    background-color: var(--text-secondary);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    padding: 15px;
    position: relative;
    width: 70%;
    max-width: 100%;
    margin-left:1.7%;
    margin-right:1.7%;
}
/* this is the actual white box */
.timeline-item-content time{
    margin-bottom: 2%;
}


.timeline-item-content::after {
    content: ' ';
    background-color: var(--text-secondary);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
}
/* this is the triangle for normal ones  */

/*
.timeline-item:nth-child(odd) .timeline-item-content {
    text-align: left;
    align-items: center;
}
*/
/* just the data.text and content is mainly left centered is altered */

.timeline-item:nth-child(odd) .timeline-item-content::after {
    left: -7.5px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}
/* each odd item triangle  will be left centered */

.timeline-item-content .tag {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 5px;
    text-transform: uppercase;
}
/* each tag is absolute and will be a the top*/


/* each odd tag will be on the right*/ 

.timeline-item-content time {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: bold;
    margin-top:2%;
}

.timeline-item-content p {
    font-size: 16px;
    line-height: 24px;
    padding:2%;
    max-width: 100%;
  background-color: var(--background);
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.timeline-item-content p button {
    float:right;
    margin-left:10%;
    margin-top:0px;
    margin-right:0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.timeline-item-content p button:hover {
    cursor: pointer;
}
.timeline-item-content button {
    color: var(--text-secondary);
}
.timeline-item-content .language{
    display:flex;
    margin-bottom: 2%;
}
.timeline-item-content .language button{
    margin-left: 2%;
    margin-right:2%;
    background-color: var(--skillbutton);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    box-shadow: 1px 1px var(--text-primary);
    opacity: 100%;
    font-size: smaller ;
}
.timelineanimation{
    width:100%;
}
.special{
    width:100%;
    display:flex;
    justify-content: flex-end;
    
}
.language button {
    cursor: auto;
}


/*
.timeline-item-content .circle {
    background-color: #fff;
    border: 3px solid #e17b77;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}
*/
/*
.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}
*/


@media only screen and (max-width: 767px) {
    .timeline-item-content .language button{
        display:none;
    }
    .timelineanimation{
        width:80%;
    }
    .special{
        width:80%;
        display:flex;
        justify-content: flex-end;
    }
    .timeline-item-content {
        width: 100%;
    }
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content {
        padding: 15px 10px;
        text-align: left;
        align-items: center;
        margin-left: 0;
        margin-right:0;
    }

    .timeline-item-content .tag {
        width: calc(100% - 10px);
        text-align: center;
        position: static
    }

    .timeline-item-content time {
        margin-top: 2%;
    }

    .timeline-item-content a {
        text-decoration: underline;
    }

    .timeline-item-content a::after {
        display: none;
    }
}