
.navigation {
    display:  flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navigation);
}

.ToggleButton{
    display:inline-block;
}
.brand-name {
    font-size:2rem;
    text-decoration: none;
    color:var(--text-primary);
    margin-left:5%;
    padding:1%;
    flex-grow: 1;
}

.navigation-menu {
    margin-right:5%;
}
.navigation-menu ul{
    display: flex;
    list-style: none;
}

.navigation-menu a {
    text-align: center;
    text-decoration: none;
    color:var(--text-primary);
}

.navigation-menu li {
    margin-right:2rem;
}
.navigation-menu a:hover {
    color: var(--text-secondary);
}

/* we do not want it in large screen */

button {
    display:none;
    background-color: transparent;
}

#hidden {
    display:flex;
}
@media screen and (max-width: 650px) {
     button{
        display:flex;
        margin-right:5%;

    }
    .navigation-menu{
        display:none;
    }

    #hidden {
        position:absolute;
        left:0px;
        top:0px;
        background-color: var(--hidden);
        height:100%;
        width:100%;
    }
    #hidden a {
        margin:5%;
    }
    #hidden ul {
        height:100%;
        display:flex;
        flex-direction: column;
        align-items:center;
        margin:0 auto;
        margin-top:auto;
        margin-bottom: auto;
    }
    #hidden li {
        margin-top:25%;
    }
}
@media screen and (max-width:400px){
    .brand-name{
        font-size: 22px;
    }
}

.Menu svg {
    fill: aqua;
}