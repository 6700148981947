.Footer{
    background-color: var(--navigation);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-container{
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
}
.main-container section{
    padding:1%;
}
.footerNavigation{
    display:block;
    padding:4%;
    text-align: center;
    text-decoration: none;
    color: var(--text-primary);
}
.footerNavigation:hover{
    color: var(--text-secondary);
}
.footerMedia{
    padding:2%;
    align-items: center;
    text-decoration: none;
    display:block;
    text-align: center;
    opacity:75%;
}
.first{
    margin-right:10%;
}
